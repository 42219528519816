<template>
    <div>
        <div id="wheelOfFortune" ref="wheelContainer">
            <div id="spin">{{ spinText }}</div>
        </div>
        <popup v-if="showPopup" :result="popupResult" @close="closePopup" />

        <div class="mt-5 px-2">
            <button class="spin-the-wheel-btn w-100" @click="spin" :disabled="spinStarted">
                <span class="spin-the-wheel-btn-text">SPIN THE WHEEL</span>
            </button>
        </div>
    </div>

</template>

<script>
const randomColor = require('randomcolor');
import { Wheel } from "spin-wheel";
import Popup from './participant.vue';
import config from '../config';


export default {
    name: 'WheelOfFortune',
    components: {
        Popup
    },
    data() {
        return {
            sectors: [],
            showPopup: false,
            popupResult: {},
            form: {},
            wheel: null,
            spinText : 'SPIN',
            spinStarted : false
        };
    },
    methods: {
        rand(m, M) {
            return Math.random() * (M - m) + m;
        },
        weightedRandom(sectors) {
            const totalWeight = sectors.reduce((sum, sector) => sum + sector.weight, 0);
            let random = Math.random() * totalWeight;
            for (let i = 0; i < sectors.length; i++) {
                if (random < sectors[i].weight) {
                    this.weightedIndex = i;
                    return i;
                }
                random -= sectors[i].weight;
            }
        },
        closePopup() {
            this.showPopup = false;
        },
        async fetchVouchers() {
            const response = await fetch(config.FetchVouchersUrl);
            const vouchers = await response.json();
            const numberOfPies = 8;
            const dummyArray = Array.from({ length: numberOfPies - vouchers.length }, () => ({ color: randomColor({ luminosity: 'dark', count: 1 })[0], label: 'Try again', id: 0, totalRemaining: 80000, weight: 6 }));
            const mergedArray = (vouchers.map((x) => { return { color: randomColor({ luminosity: 'dark', count: 1 })[0], label: `NGN ${x.amount}`, id: x.id, totalRemaining: x.totalRemaining, weight: this.getWeight(x.amount) } })).concat(dummyArray);
            let result = this.shuffle(mergedArray);
            result = this.shuffle(result);
            return result;
        },
        getWeight(amount) {
            let result = 1;
            switch (amount) {
                case 500:
                    result = 3.2;
                    break;
                case 200:
                    result = 3.2;
                    break;
                case 100:
                    result = 5.5;
                    break;
                default:
                    break;
            }
            return result;
        },
        async disburseWinnings(airtimeId) {
            const disbursementModel = {
                name: `${this.form.firstname} ${this.form.lastname}`,
                emailAddress: this.form.email,
                phonenumber: `+234${this.form.phone.substring(1)}`,
                state: this.form.state,
                lga: this.form.city,
                code: this.form.code,
                airtimeId: airtimeId
            }
            try {
                const response = await fetch(config.DisburseVoucherUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(disbursementModel),
                });

                if (response.ok) {
                    return true;
                } else {
                    return false
                }
            } catch (error) {
                console.error('Error validating coupon code:', error);
                alert('An error occurred while validating your coupon code.');
            }
        },
        shuffle(array) {
            let currentIndex = array.length;
            while (currentIndex !== 0) {
                let randomIndex = Math.floor(this.rand(0.35, 0.90) * currentIndex);
                currentIndex--;
                [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
            }
            return array;
        },
        async testProbability(){
            let results = [];
            this.sectors = await this.fetchVouchers();
            const wheelContainer = this.$refs.wheelContainer;
            const props = await this.buildWheelProps(this.sectors);       
            console.log(this.sectors);
            this.wheel = new Wheel(wheelContainer, props); 
            for (let index = 0; index < 10; index++) {
                let choice = this.weightedRandom(this.sectors);
                let sector = this.sectors[choice];
                // console.log(`Sector - ${sector.label} - Weight - ${sector.weight}`);
                results.push(sector.label);                
            }

            let fiveHundred = results.filter(x=> x =='NGN 500').length;
            let tryagain = results.filter(x=> x =='Try again').length;
            let twoHundred = results.filter(x=> x =='NGN 200').length;
            let oneHundred = results.filter(x=> x =='NGN 100').length;

            console.log('Five Hundred',fiveHundred);
            console.log('Two Hundred',twoHundred);
            console.log('One Hundred',oneHundred);
            console.log('Try again',tryagain);
            var winProb = Math.round(((fiveHundred + twoHundred + oneHundred) / results.length) * 100);
            console.log('Win Probability', winProb);
        },
        async buildWheelProps(sectors) {
            let props = {
                items: [],
                borderColor: '#FFF',
                borderWidth: 5,
                isInteractive: false,
                debug: false,
                lineColor: '#FFF',
                itemLabelAlign: 'right',
                itemLabelFontSizeMax: 16,
                pointerAngle: 0,
                onCurrentIndexChange: e => this.onCurrentIndexChanged(e),
                onRest: e => this.onSpinCompleted(e)
            };
            sectors.forEach(element => {
                props.items.push({
                    backgroundColor: element.color,
                    label: element.label,
                    labelColor: '#fff',
                    value: element.id
                });
            });

            return props;
        },
        spin(){
            const finalAnswer = this.weightedRandom(this.sectors);
            this.wheel.spinToItem(finalAnswer, 6000, true, 6, 1,null);
            this.spinStarted = true;
        },
        onCurrentIndexChanged(event){
            this.spinText = this.sectors[event.currentIndex]?.label;
            document.querySelector('#spin').style.background = this.sectors[event.currentIndex]?.color;
        },
        async onSpinCompleted(event){
            const sector = this.sectors[event.currentIndex];
            var model = JSON.parse(JSON.stringify(sector));
            this.popupResult = model;
            await this.disburseWinnings(sector.id);
            localStorage.clear();
            this.showPopup = true;
        }
    },
    async mounted() {
        if (localStorage.getItem('form')) {
            try {
                this.form = JSON.parse(localStorage.getItem('form'));
            } catch (e) {
                localStorage.removeItem('form');
            }
        }
        const wheelContainer = this.$refs.wheelContainer;
        this.sectors = await this.fetchVouchers();
        const props = await this.buildWheelProps(this.sectors);       
        // console.log(props);
        this.wheel = new Wheel(wheelContainer, props);      
    }
};
</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    height: 100vh;
    display: grid;
    place-items: center;
}

#wheelOfFortune {
    display: inline-block;
    position: relative;
    overflow: hidden;
    height: 400px;
    width: 400px;
}

#wheel {
    display: block;
    width: 100%;
    height: auto;
    z-index: 1;
    position: relative;
}

#spin {
    font-size: 16px;
    user-select: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 55%;
    left: 55%;
    width: 20%;
    height: 20%;
    margin: -15%;
    background: #b51946;
    color: #fff;
    box-shadow: 0 0 0 8px currentColor, 0 0px 15px 5px rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    transition: 0.8s;
    z-index: 1;
}


#spin::after {
    content: '';
    position: absolute;
    top: -17px;
    border: 10px solid transparent;
    border-bottom-color: currentColor;
    border-top: none;
}

.border {
    position: relative;
    padding: 10px;
    border-radius: 50%;
    overflow: hidden;
    border: none !important;
}

.border::before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    border-radius: inherit;
    background-color: #fff;
    /* background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000); */
    background-size: 400%;
    z-index: 0;
    /* animation: borderAnimation 10s linear infinite; */
}

@keyframes borderAnimation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@media (max-width: 600px) {
    #spin {
        font-size: 0.8em;
        margin: -12%;
        top: 52%;
        left: 52%;
    }

    #spin::after {
        top: -14px;
        border-width: 7px;
    }

    #wheelOfFortune {   
    height: 320px;
    width: 320px;
}
}
</style>
